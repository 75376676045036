import { css } from 'styled-components'

export const CSStextLink = css`
  color: inherit;
  text-decoration: underline;
  text-decoration-style: dotted;
  @media (hover: hover) {
    &:hover {
      text-decoration-style: solid;
    }
  }
`
