import { useState, useEffect, useRef } from 'react'
import useRecaptcha from './useRecaptchaV3'
import firebase from '../components/firebase'
const env = process.env.NODE_ENV

// 'action' must be one of the functions/constants COLLECTIONS' ids.
function useRecaptchaFirestoreSubmit({ action, callback }) {
  const [isLoading, setIsLoading] = useState()
  const [isSuccess, setIsSuccess] = useState()
  const captcha = useRecaptcha()

  let mounted = useRef(true)
  useEffect(() => () => (mounted.current = false), [])

  async function handleSubmit(data) {
    if (!captcha.isReady) return
    try {
      setIsLoading(true)
      const captchaToken = await captcha.getToken({ action })
      await firebase.functions().httpsCallable('handleLandingSubmit')({
        env,
        action,
        captchaToken,
        ...data,
      })
      if (callback) callback()
      if (mounted.current) setIsSuccess(true)

      // // TESTING
      // setTimeout(() => {
      //   console.log('mounted.current', mounted.current)
      //   if (mounted.current) {
      //     setIsLoading(false)
      //     setIsSuccess(true)
      //   }
      // }, 4000)
    } catch (error) {
      console.log('handleSubmit error: ', error)
    } finally {
      if (mounted.current) setIsLoading(false)
    }
  }

  function resetSuccess() {
    setIsSuccess(false)
  }

  return { isLoading, isSuccess, resetSuccess, handleSubmit }
}

export default useRecaptchaFirestoreSubmit
