import React, { useState, useEffect } from 'react'
import SEO from '../components/seo'
import styled from 'styled-components'
// import tw from 'twin.macro'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Link from '../components/Link'
import Layout from '../components/layout'
import Hero from '../components/Hero'
import Button from '../components/Button'
import SvgCharts from '../svg/SvgCharts'
import TrendingUp from '../svg/TrendingUp'
import Star from '../svg/Star'
import { Section } from '../components/layout'
import ThesesGrid from '../components/ThesesGrid'
import { THESES_B2B } from '../data/theses-why'
import Inp from '../components/Input'
import { useRecaptchaFirestoreSubmit } from '../helpers'
import { CSStextLink } from '../components/UI'
import { pushGTMEvent, GA_EVENT_CATEGORY } from '../analytics'

export const LinkForPartners = styled(Link).attrs({
  to: '/partner',
})``

const LinkUnderlined = styled(Link)`
  ${CSStextLink}
`
const SemiBold = styled.span`
  font-weight: 600;
`

const Input = styled(Inp).attrs({
  color: 'text-darkbg-700',
  placeHolderColor: 'placeholder-darkbg-800',
  bgColor: 'bg-btngray-900',
  bgOpacity: 0.16,
  borderColor: 'focus:border-b2b-900',
  containerCN: 'mb-8',
})``

export default () => {
  const action = 'place_application'
  const callback = () =>
    pushGTMEvent({ event: `submit_partner_form_${GA_EVENT_CATEGORY}` })
  const {
    isLoading,
    isSuccess,
    resetSuccess,
    handleSubmit,
  } = useRecaptchaFirestoreSubmit({ action, callback })

  const initialState = {
    place_name: '',
    city: '',
    address: '',
    person_name: '',
    email: '',
    phone: '',
  }
  const [state, setState] = useState(initialState)

  function handleChange(e) {
    setState({ ...state, [e.target.name]: e.target.value })
    if (isSuccess) resetSuccess()
  }
  function onSubmit(e) {
    e.preventDefault()
    const data = Object.entries(state)
      .filter(([k, v]) => Boolean(v))
      .reduce((o, [k, v]) => ({ ...o, [k]: v }), {})
    handleSubmit(data)
  }

  // Handle autoscroll for email comers with UTM.
  // Otherwise Gmail strips the #param out of URL.
  useEffect(() => {
    if (typeof window === 'undefined') return
    const p = new URL(document.location).searchParams
    const scrollTarget = p.get('target')
    if (scrollTarget === action) {
      const section = document.querySelector(`#${action}`)
      if (section) window.scrollTo(0, section.offsetTop)
    }
  }, [])

  const { place_name, city, address, person_name, email, phone } = state
  return (
    <Layout>
      <SEO title="Стать партнёром" />
      <div className="overflow-hidden">
        <Hero
          title="Удвойте число своих клиентов"
          // subtitle="Сервис для принятия посетителей фитнес-заведений через мобильное приложение"
          subtitle="Мобильный сервис для заведений фитнеса, цифровой пропуск для посетителей, CRM."
          renderButtons={() => (
            <>
              <AnchorLink href={`#${action}`} offset="80" className="lg:w-1/2">
                <Button
                  title="Подключить"
                  color="text-b2b-900"
                  bgColor="bg-b2b-900"
                />
              </AnchorLink>
              <AnchorLink
                href="#why"
                offset="80"
                className="block mt-4 lg:mt-0 lg:ml-5 lg:w-1/2"
              >
                <Button
                  title="Почему Octopass?"
                  color="text-darkbg-700"
                  bgColor="bg-btngray-900"
                  bgOpacity={0.16}
                />
              </AnchorLink>
            </>
          )}
          namesL="w-full sm:w-1/2 text-4xl sm:text-5xl"
        >
          <div className="pt-16 mb-20 w-full sm:w-1/2 sm:pl-10 origin-left sm:transform sm:scale-110 lg:scale-125">
            <SvgCharts />
          </div>
        </Hero>
      </div>

      <div className="mt-12 mb-40 sm:mb-48">
        <Section
          id="why"
          eyebrow="Почему Octopass?"
          eyebrowColorNames="text-golden-900"
          title="Развитие без инвестиций"
          subtitle="Новые возможности для фитнес-клубов и их клиентов."
        >
          <ThesesGrid
            items={THESES_B2B}
            className="md:grid-cols-2"
            iconColorNames="bg-golden-100 text-golden-900"
          />
        </Section>
      </div>

      <div className="mt-12 mb-32">
        <Section
          id={action}
          title="Заявка на подключение"
          subtitle="Расскажите о себе, и мы свяжемся, чтобы обсудить детали"
        >
          <div className="flex mt-12">
            <form className="sm:w-3/5 md:w-1/2" onSubmit={onSubmit}>
              <Input
                placeholder="Ф.И.О. ⁃ обязательное поле"
                name="person_name"
                value={person_name}
                onChange={handleChange}
                required
                disabled={isLoading}
              />
              <Input
                placeholder="Телефон ⁃ обязательное поле"
                name="phone"
                value={phone}
                onChange={handleChange}
                type="tel"
                // pattern="[0-9]{11}"
                required
                disabled={isLoading}
              />
              <Input
                placeholder="Email"
                name="email"
                value={email}
                onChange={handleChange}
                type="email"
                // required
                disabled={isLoading}
              />
              <Input
                placeholder="Название заведения"
                name="place_name"
                value={place_name}
                onChange={handleChange}
                // required
                disabled={isLoading}
              />
              <Input
                placeholder="Город"
                name="city"
                value={city}
                onChange={handleChange}
                // required
                disabled={isLoading}
              />
              <Input
                placeholder="Адрес заведения"
                name="address"
                value={address}
                onChange={handleChange}
                disabled={isLoading}
              />
              <p className="text-sm text-gray-500">
                Нажимая кнопку <SemiBold>Отправить</SemiBold>, я подтверждаю
                свое согласие на обработку моих персональных данных в
                соответствии с действующим законодательством и на условиях{' '}
                <LinkUnderlined to="/privacy">
                  Политики конфиденциальности
                </LinkUnderlined>
                .
              </p>
              <Button
                className="mt-8 sm:w-1/2"
                title="Отправить"
                color="text-b2b-900"
                bgColor="bg-b2b-900"
                isLoading={isLoading}
                isSuccess={isSuccess}
                disabled={isLoading || isSuccess}
              />
            </form>
            <div className="hidden sm:flex sm:w-2/5 md:w-1/2 flex-col pl-10">
              <TrendingUp
                className="fill-current h-40 text-b2b-900 ml-auto"
                style={{ opacity: 0.05 }}
              />
              <Star className="fill-current h-32 text-golden-900 opacity-10 mx-auto mt-40" />
            </div>
          </div>
        </Section>
      </div>
    </Layout>
  )
}
