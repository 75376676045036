import React from 'react'

export default props => (
  <svg {...props} viewBox="0 0 448 256" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="m432 0c8.731359 0 15.829205 6.99390104 15.996964 15.6851293l.003036.3148707v112c0 8.836556-7.163444 16-16 16-8.731359 0-15.829205-6.993901-15.996964-15.685129l-.003036-.314871v-96h-96c-8.731359 0-15.829205-6.993901-15.996964-15.6851293l-.003036-.3148707c0-8.7313589 6.993901-15.82920469 15.685129-15.99696358l.314871-.00303642z" />
      <path d="m404.686292 20.6862915c6.248388-6.2483887 16.379028-6.2483887 22.627416 0 6.161606 6.1616055 6.247184 16.0983706.256734 22.3648416l-.256734.2625754-137.368708 137.3687085c-9.002061 9.004116-21.21271 14.062749-33.945 14.062749-12.530191 0-24.555168-4.899316-33.512685-13.635737l-.431023-.425721-50.741292-50.741291c-3.000687-3.001372-7.070903-4.687583-11.315-4.687583-4.10719 0-8.051537 1.579177-11.022473 4.402416l-.293819.286458-121.3699995 121.37c-6.2483887 6.248389-16.3790283 6.248389-22.627417 0-6.16160549-6.161605-6.24718335-16.09837-.25673356-22.364841l.25673356-.262575 121.3687085-121.368709c9.002061-9.0041164 21.21271-14.0627493 33.945-14.0627493 12.530191 0 24.555168 4.8993159 33.512685 13.6357373l.431023.425721 50.741292 50.741291c3.000687 3.001372 7.070903 4.687583 11.315 4.687583 4.10719 0 8.051537-1.579177 11.022473-4.402416l.293819-.286458z" />
    </g>
  </svg>
)
