import { createGlobalStyle } from 'styled-components'
import { useScript } from './customHooks'
const site_key = process.env.GATSBY_RECAPTCHA_SITE_KEY // From .env file.

export const RecaptchaBadgeHideStyle = createGlobalStyle`
  & .grecaptcha-badge {
    visibility: hidden;
  }
`

const isDev = process.env.NODE_ENV === 'development'

export default () => {
  const [isReady] =
    !isDev &&
    useScript(`https://www.google.com/recaptcha/api.js?render=${site_key}`)
  const getToken = ({ action }) =>
    // console.log(site_key)
    isReady &&
    typeof window !== 'undefined' && // Check for Gatsbyjs SSR
    window.grecaptcha.execute(site_key, { action }).then(token => token)

  return { isReady, getToken }
}
