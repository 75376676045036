import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Link from '../Link'

const GrayImage = styled.img`
  filter: grayscale(1);
  opacity: 0.38;
`

export default ({ main }) => {
  const { cardsImage } = useStaticQuery(
    graphql`
      query {
        cardsImage: file(relativePath: { eq: "pay-with.svg" }) {
          publicURL
        }
      }
    `
  )
  const color = main ? 'text-gray-200' : 'text-gray-800'
  const names = {
    container: (main ? 'bg-darkbg-800' : '') + ' select-none',
    link: `opacity-50 hover:opacity-100 ${color} block mr-8 mb-3`,
    copy: `opacity-50 ${color}`
  }
  return (
    <footer className={names.container}>
      <div className="max-w-screen-lg m-auto px-6 md:px-6">
        {!main && (
          <div className="border-t border-btngray-900 opacity-25"></div>
        )}
        <div className="flex justify-between pt-6 flex-wrap">
          <div>
            <div className="flex text-prebase font-semibold items-center flex-wrap">
              <Link className={names.link} to="/terms">
                Соглашение
              </Link>
              <Link className={names.link} to="/privacy">
                Конфиденциальность
              </Link>
              <a className={names.link} href="mailto:support@octopass.ru">
                Поддержка
              </a>
            </div>

            <div className={`text-sm pt-2 pb-6 ${names.copy}`}>
              © Symbiotic LLC
            </div>
          </div>
          <GrayImage
            className="h-6 mb-6"
            src={cardsImage.publicURL}
            alt="Автооплата картой онлайн"
          />
        </div>
      </div>
    </footer>
  )
}
