import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export const SectionHeader = styled.div`
  ${tw`lg:text-center`}
`
export const Eyebrow = styled.p`
  ${tw`text-base leading-6 font-semibold tracking-wide uppercase`}
`
export const Title = styled.h2`
  ${tw`mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 lg:m-auto`}
  /* ${tw`max-w-md`} */
  max-width: 32rem;
`
export const Subtitle = styled.p`
  ${tw`mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto`}
`
export const SectionContent = styled.div`
  ${tw`mt-10`}
`

export const SectionContainer = ({ id, children }) => (
  <section
    id={id}
    // className="max-w-screen-xl mx-auto px-4 sm:px-6  overflow-hidden"
    className="max-w-screen-lg mx-auto px-6 overflow-hidden"
  >
    {children}
  </section>
)

// <div className="mt-12 mb-40 sm:mb-64">
export default ({
  id,
  eyebrow,
  title,
  subtitle,
  children,
  eyebrowColorNames = '',
}) => (
  <SectionContainer id={id}>
    <SectionHeader>
      {eyebrow && <Eyebrow className={eyebrowColorNames}>{eyebrow}</Eyebrow>}
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </SectionHeader>
    <SectionContent>{children}</SectionContent>
  </SectionContainer>
)
