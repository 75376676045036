import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import Spinner from '../svg/Spinner'
import CheckmarkCircle from '../svg/CheckmarkCircle'

const Btn = styled.button`
  height: 3.25rem;
  ${tw`py-2 px-3 rounded-xl flex items-center justify-center relative overflow-hidden`}
  ${tw`focus:outline-none`}
  @media (hover: hover) {
    &:hover,
    &:disabled {
      & .absolute.inset-0 {
        opacity: ${p => 0.7 * p.bgOpacity} !important;
      }
    }
    &:active:not(:disabled) {
      & .absolute.inset-0 {
        opacity: ${p => p.bgOpacity} !important;
      }
    }
  }
`

const Button = ({
  title,
  Icon,
  className = '',
  color,
  bgColor,
  bgOpacity = 0.2,
  iconStyle,
  isLoading,
  isSuccess,
  ...rest
}) => {
  const iHeight = '1.75rem'
  const Content = () =>
    isSuccess ? (
      <CheckmarkCircle
        className="fill-current text-mygreen-500"
        style={{ height: iHeight }}
      />
    ) : isLoading ? (
      <Spinner className="fill-current h-5" />
    ) : (
      <>
        {Icon && (
          <Icon
            className="fill-current absolute -mr-5"
            style={{ right: '100%', height: iHeight, ...iconStyle }}
          />
        )}
        {title}
      </>
    )

  const backColor = isSuccess ? 'bg-mygreen-500' : bgColor

  return (
    <Btn {...{ bgOpacity, ...rest }} className={`w-full ${className}`}>
      <div
        className={`absolute inset-0 ${backColor}`}
        style={{ opacity: bgOpacity }}
      ></div>
      <div
        className={`relative flex items-center leading-none pointer-events-none ${color} text-prebase font-semibold tracking-tight`}
        style={Icon ? { paddingLeft: '1.4rem' } : {}}
      >
        <Content />
      </div>
    </Btn>
  )
}

export default Button
