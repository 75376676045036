import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const AbsBackground = styled.div.attrs(p => ({
  className: `${p.bgColor}`,
}))`
  ${tw`absolute inset-0 pointer-events-none rounded-xl`}
  opacity: ${p => p.bgOpacity};
`

const Input = styled.input.attrs(p => ({
  className: `${p.placeHolderColor} placeholder-opacity-50 ${p.borderColor} ${p.color}`,
}))`
  height: 3.25rem;
  ${tw`py-4 px-5 block w-full bg-transparent z-10`}
  ${tw`focus:outline-none appearance-none rounded-xl`}
  ${tw`border-solid border-2 border-transparent`}
  /* ${p => tw`${p.className}`} */
  &::placeholder {
    letter-spacing: -0.25px;
  }
  &:hover:not(:disabled) ~ div {
    opacity: ${p => 0.7 * p.bgOpacity};
  }  
  &&:focus ~ div {
    opacity: 0;
  }
`

export default ({ bgColor, bgOpacity = 0.06, containerCN = '', ...rest }) => (
  <div className={`w-full relative ${containerCN}`}>
    <Input {...{ bgOpacity, ...rest }} />
    <AbsBackground {...{ bgOpacity, bgColor }} />
  </div>
)

// export default React.forwardRef(
//   (ref, { bgColor, bgOpacity = 0.06, containerNames, ...rest }) => (
//     <div className={`w-full relative ${containerNames || ''}`}>
//       <Input {...{ bgOpacity, ...rest }} />
//       <AbsBackground {...{ bgOpacity, bgColor }} />
//     </div>
//   )
// )
