import React from 'react'
// import styled from 'styled-components'
// import tw from 'twin.macro'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// import Headroom from 'react-headroom'
import Link from '../Link'
import Logo from '../../svg/Logo'
import Brand from '../../svg/OctopassBrand'
import { LinkForPartners } from '../../pages/partner'

export default ({ main }) => {
  const color = main ? 'text-gray-100' : 'text-gray-900'
  const names = {
    container:
      (main ? 'bg-darkbg-800 text-gray-100' : 'bg-white text-gray-900') +
      ' select-none',
    // link: 'mx-4 py-1 border-b border-transparent hover:border-current',
    // link: 'mx-4 py-1 hover:underline',
    link: `py-1 opacity-75 ${color} hover:opacity-100`
  }
  return (
    // <Headroom style={{ zIndex: 20 }}>
    <header className={names.container}>
      <div className="max-w-screen-lg m-auto px-6 py-4 md:py-6 flex justify-between items-center">
        <Link to="/">
          <div className="flex items-center">
            <Logo className="fill-current mr-1" style={{ height: '1.9rem' }} />
            <Brand
              className="fill-current"
              style={{
                height: '1.4rem',
                marginBottom: '-.08rem'
              }}
            />
          </div>
        </Link>

        <div className="font-semibold text-prebase hidden sm:flex">
          {main ? (
            <AnchorLink
              className={`mx-2 md:mx-4 ${names.link}`}
              href="#how"
              offset="80"
            >
              Как это работает
            </AnchorLink>
          ) : (
            <Link className={`mx-2 md:mx-4 ${names.link}`} to="/#how">
              Как это работает
            </Link>
          )}

          {/* <AnchorLink className={`mx-2 md:mx-4 ${names.link}`} href="#">
              Скачать приложение
            </AnchorLink> */}
          <LinkForPartners className={`ml-2 md:ml-12 ${names.link}`}>
            Стать партнёром
          </LinkForPartners>
        </div>
      </div>
    </header>
    // </Headroom>
  )
}
