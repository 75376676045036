import React from 'react'

const Item = ({ index, title, details, Svg, iconColorNames = '' }) => (
  <li className={index ? `mt-10 md:mt-0` : ``}>
    <div className="flex">
      <div className="flex-shrink-0">
        <div
          className={`flex items-center justify-center h-12 w-12 rounded-full ${iconColorNames}`}
        >
          <Svg
            className="h-6 w-6 fill-current"
            // stroke="currentColor"
            // fill="none"
          />
        </div>
      </div>
      <div className="ml-4">
        <h5 className="text-lg leading-6 font-medium">{title}</h5>
        <p className="mt-2 text-base leading-6 text-gray-500">{details}</p>
      </div>
    </div>
  </li>
)

export default ({ items, className = '', iconColorNames }) => {
  return (
    <ul className={`md:grid md:col-gap-8 md:row-gap-10 ${className}`}>
      {items.map((item, index) => (
        <Item key={index} index={index} {...{ ...item, iconColorNames }} />
      ))}
    </ul>
  )
}
