import React from 'react'
import { createGlobalStyle } from 'styled-components'
import Footer from './Footer'
import Header from './Header'
import { RecaptchaBadgeHideStyle } from '../../helpers'

const DarkBackground = createGlobalStyle`
  html {
    background: rgb(42, 47, 55);
    /* background: linear-gradient(0, rgba(42, 47, 55, 1) 0%, rgba(42, 47, 55, 1) 50%, rgba(42, 47, 55, 0) 51%, rgba(42, 47, 55, 0) 100% ); */
  }
`

export default ({ main, children, headless }) => {
  // React.useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const { host, pathname } = document.location
  //     alert(`host + pathname: ${host}${pathname}`)
  //   }
  // }, [])

  return (
    <>
      {main && <DarkBackground />}
      <RecaptchaBadgeHideStyle />
      <div
        className={`antialiased font-inter text-gray-900 tracking-tight ${
          headless ? '' : 'bg-white'
        }`}
      >
        {!headless && <Header main={main} />}
        <main className="select-none">{children}</main>
        {!headless && <Footer main={main} />}
      </div>
    </>
  )
}
