import React from 'react'

export const THESES_B2C = [
  {
    title: `Экономишь время`,
    details: `Моментальный доступ к услугам. Автооплата. Заходи и тренируйся.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <circle cx="256" cy="272" r="16" />
        <path d="m280 81.5v-9.5a24 24 0 0 0 -48 0v9.5a191 191 0 0 0 -84.43 32.13l-10.57-10.63a24 24 0 0 0 -34 34l8.6 8.6a191.17 191.17 0 0 0 -47.6 126.4c0 105.87 86.13 192 192 192s192-86.13 192-192c0-97.74-73.42-178.66-168-190.5zm-24 238.5a48 48 0 0 1 -16-93.25v-74.75a16 16 0 0 1 32 0v74.75a48 48 0 0 1 -16 93.25z" />
      </svg>
    ),
  },
  {
    title: `Управляешь бюджетом`,
    details: `Куплен абонемент, а ходишь не часто? Теперь плати только за время, когда ходишь.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m95.5 104h320a87.73 87.73 0 0 1 11.18.71 66 66 0 0 0 -77.51-55.56l-263.17 44.93h-.3a66 66 0 0 0 -41.07 26.13 87.57 87.57 0 0 1 50.87-16.21z" />
        <path d="m415.5 128h-320a64.07 64.07 0 0 0 -64 64v192a64.07 64.07 0 0 0 64 64h320a64.07 64.07 0 0 0 64-64v-192a64.07 64.07 0 0 0 -64-64zm-47.5 192a32 32 0 1 1 32-32 32 32 0 0 1 -32 32z" />
        <path d="m32 259.5v-99.5c0-21.67 12-58 53.65-65.87 35.35-6.63 70.35-6.63 70.35-6.63s23 16 4 16-18.5 24.5 0 24.5 0 23.5 0 23.5l-74.5 84.5z" />
      </svg>
    ),
  },
  {
    title: `Пробуешь новое`,
    details: `Используй любое заведение на карте. Новый клуб, любая сеть, хоть каждый день.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m328.85 156.79a26.69 26.69 0 1 0 18.88 7.81 26.6 26.6 0 0 0 -18.88-7.81z" />
        <path d="m477.44 50.06a.29.29 0 0 1 0-.09 20.4 20.4 0 0 0 -15.13-15.3c-29.8-7.27-76.68.48-128.63 21.28-52.36 21-101.42 52-134.58 85.22a320.7 320.7 0 0 0 -29.55 33.83c-22.33-1-42 2.18-58.57 9.41-57.74 25.41-74.23 90.44-78.62 117.14a25 25 0 0 0 27.19 29h.13l64.32-7.02c.08.82.17 1.57.24 2.26a34.36 34.36 0 0 0 9.9 20.72l31.39 31.41a34.27 34.27 0 0 0 20.71 9.91l2.15.23-7 64.24s0 .08 0 .13a25 25 0 0 0 24.61 27.57 25.25 25.25 0 0 0 4.15-.34c26.85-4.32 91.85-20.61 117.19-78.66 7.17-16.46 10.34-36.05 9.45-58.34a314.78 314.78 0 0 0 33.95-29.55c33.43-33.26 64.53-81.92 85.31-133.52 20.69-51.36 28.48-98.59 21.39-129.53zm-107.06 174.88a58.77 58.77 0 1 1 0-83.07 58.3 58.3 0 0 1 0 83.07z" />
        <path d="m161.93 386.44a16 16 0 0 0 -11 2.67c-6.39 4.37-12.81 8.69-19.29 12.9-13.11 8.52-28.79-6.44-21-20l12.15-21a16 16 0 0 0 -15.16-24.91 61.25 61.25 0 0 0 -35.63 17.46c-3.66 3.67-14.79 14.81-20.78 57.26a357.94 357.94 0 0 0 -3.22 36.77 16 16 0 0 0 16 16.41h.4a359.87 359.87 0 0 0 36.8-3.2c42.47-6 53.61-17.14 57.27-20.8a60.49 60.49 0 0 0 17.39-35.74 16 16 0 0 0 -13.93-17.82z" />
      </svg>
    ),
  },
  {
    title: `Держишь темп`,
    details: `Длительность? Регулярность? Следи за историей тренировок в приложении.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m480 496h-432a32 32 0 0 1 -32-32v-432a16 16 0 0 1 32 0v432h432a16 16 0 0 1 0 32z" />
        <path d="m156 432h-40a36 36 0 0 1 -36-36v-152a36 36 0 0 1 36-36h40a36 36 0 0 1 36 36v152a36 36 0 0 1 -36 36z" />
        <path d="m300 432h-40a36 36 0 0 1 -36-36v-200a36 36 0 0 1 36-36h40a36 36 0 0 1 36 36v200a36 36 0 0 1 -36 36z" />
        <path d="m443.64 432h-40a36 36 0 0 1 -36-36v-264a36 36 0 0 1 36-36h40a36 36 0 0 1 36 36v264a36 36 0 0 1 -36 36z" />
      </svg>
    ),
  },
  {
    title: `Вместе с друзьями`,
    details: `Занимайся с семьей и друзьями. Им нужно просто установить приложение.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m336 256c-20.56 0-40.44-9.18-56-25.84-15.13-16.25-24.37-37.92-26-61-1.74-24.62 5.77-47.26 21.14-63.76s36.86-25.4 60.86-25.4c23.83 0 45.38 9.06 60.7 25.52 15.47 16.62 23 39.22 21.26 63.63-1.67 23.11-10.9 44.77-26 61-15.52 16.67-35.39 25.85-55.96 25.85z" />
        <path d="m467.83 432h-263.65a27.71 27.71 0 0 1 -22-10.67 30.22 30.22 0 0 1 -5.26-25.79c8.42-33.81 29.28-61.85 60.32-81.08 27.55-17.06 62.62-26.46 98.76-26.46 36.85 0 71 9 98.71 26.05 31.11 19.13 52 47.33 60.38 81.55a30.27 30.27 0 0 1 -5.32 25.78 27.68 27.68 0 0 1 -21.94 10.62z" />
        <path d="m147 260c-35.19 0-66.13-32.72-69-72.93-1.42-20.6 5-39.65 18-53.62 12.86-13.83 31-21.45 51-21.45s38 7.66 50.93 21.57c13.1 14.08 19.5 33.09 18 53.52-2.87 40.2-33.8 72.91-68.93 72.91z" />
        <path d="m212.66 291.45c-17.59-8.6-40.42-12.9-65.65-12.9-29.46 0-58.07 7.68-80.57 21.62-25.51 15.83-42.67 38.88-49.6 66.71a27.39 27.39 0 0 0 4.79 23.36 25.32 25.32 0 0 0 20.09 9.76h111a8 8 0 0 0 7.87-6.57c.11-.63.25-1.26.41-1.88 8.48-34.06 28.35-62.84 57.71-83.82a8 8 0 0 0 -.63-13.39c-1.57-.92-3.37-1.89-5.42-2.89z" />
      </svg>
    ),
  },
  {
    title: `Продолжаешь в поездках`,
    details: `Используй заведения в другом городе. Сохраняй форму в командировках.`,
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m186.62 464h-26.62a16 16 0 0 1 -14.57-22.6l64.46-142.25-96.79-2.15-35.3 42.77c-6.73 8.46-12.1 12.23-25.8 12.23h-17.92a17.66 17.66 0 0 1 -14.7-7.06c-2.38-3.21-4.72-8.65-2.44-16.41l19.82-71c.15-.53.33-1.06.53-1.58a.38.38 0 0 0 0-.15 14.82 14.82 0 0 1 -.53-1.59l-19.84-71.45c-2.15-7.61.2-12.93 2.56-16.06a16.83 16.83 0 0 1 13.6-6.7h18.92c10.23 0 20.16 4.59 26 12l34.57 42.05 97.32-1.44-64.44-142a16 16 0 0 1 14.55-22.61h26.91a25 25 0 0 1 19.35 9.8l125.05 152 57.77-1.52c4.23-.23 15.95-.31 18.66-.31 55.26.03 88.26 17.97 88.26 48.03 0 9.46-3.78 27-29.07 38.16-14.93 6.6-34.85 9.94-59.21 9.94-2.68 0-14.37-.08-18.66-.31l-57.76-1.54-125.36 152a25 25 0 0 1 -19.32 9.75z" />
      </svg>
    ),
  },
]

export const THESES_B2B = [
  {
    title: `Привлекаете новых клиентов с нулевыми вложениями`,
    details:
      'Пользователи Octopass – ваша новая активно растущая аудитория. Мы несем расходы на привлечение. Вы получаете 100% прибыли от продажи дополнительных услуг.',
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m336 256c-20.56 0-40.44-9.18-56-25.84-15.13-16.25-24.37-37.92-26-61-1.74-24.62 5.77-47.26 21.14-63.76s36.86-25.4 60.86-25.4c23.83 0 45.38 9.06 60.7 25.52 15.47 16.62 23 39.22 21.26 63.63-1.67 23.11-10.9 44.77-26 61-15.52 16.67-35.39 25.85-55.96 25.85z" />
        <path d="m467.83 432h-263.65a27.71 27.71 0 0 1 -22-10.67 30.22 30.22 0 0 1 -5.26-25.79c8.42-33.81 29.28-61.85 60.32-81.08 27.55-17.06 62.62-26.46 98.76-26.46 36.85 0 71 9 98.71 26.05 31.11 19.13 52 47.33 60.38 81.55a30.27 30.27 0 0 1 -5.32 25.78 27.68 27.68 0 0 1 -21.94 10.62z" />
        <path d="m147 260c-35.19 0-66.13-32.72-69-72.93-1.42-20.6 5-39.65 18-53.62 12.86-13.83 1-21.45 51-21.45s38 7.66 50.93 21.57c13.1 14.08 19.5 33.09 18 53.52-2.87 40.2-33.8 72.91-68.93 72.91z" />
        <path d="m212.66 291.45c-17.59-8.6-40.42-12.9-65.65-12.9-29.46 0-58.07 7.68-80.57 21.62-25.51 15.83-42.67 38.88-49.6 66.71a27.39 27.39 0 0 0 4.79 23.36 25.32 25.32 0 0 0 20.09 9.76h111a8 8 0 0 0 7.87-6.57c.11-.63.25-1.26.41-1.88 8.48-34.06 28.35-62.84 57.71-83.82a8 8 0 0 0 -.63-13.39c-1.57-.92-3.37-1.89-5.42-2.89z" />
      </svg>
    ),
  },
  {
    title: `Повышаете средний чек и продажи дополнительных услуг`,
    details:
      'С персональными рекомендациями и напоминаниями Octopass ваши клиенты чаще тренируются и покупают больше услуг.',
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="m95.5 104h320a87.73 87.73 0 0 1 11.18.71 66 66 0 0 0 -77.51-55.56l-263.17 44.93h-.3a66 66 0 0 0 -41.07 26.13 87.57 87.57 0 0 1 50.87-16.21z" />
        <path d="m415.5 128h-320a64.07 64.07 0 0 0 -64 64v192a64.07 64.07 0 0 0 64 64h320a64.07 64.07 0 0 0 64-64v-192a64.07 64.07 0 0 0 -64-64zm-47.5 192a32 32 0 1 1 32-32 32 32 0 0 1 -32 32z" />
        <path d="m32 259.5v-99.5c0-21.67 12-58 53.65-65.87 35.35-6.63 70.35-6.63 70.35-6.63s23 16 4 16-18.5 24.5 0 24.5 0 23.5 0 23.5l-74.5 84.5z" />
      </svg>
    ),
  },
  {
    title: `Сохраняете своих клиентов`,
    details:
      'Позвольте клиентам платить поминутно – это повышает их лояльность в период вынужденной экономии.',
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 512 512">
        <path d="M394,480a16,16,0,0,1-9.39-3L256,383.76,127.39,477a16,16,0,0,1-24.55-18.08L153,310.35,23,221.2A16,16,0,0,1,32,192H192.38l48.4-148.95a16,16,0,0,1,30.44,0l48.4,149H480a16,16,0,0,1,9.05,29.2L359,310.35l50.13,148.53A16,16,0,0,1,394,480Z" />
      </svg>
    ),
  },
  {
    title: `Сокращаете расходы с octoCRM`,
    details:
      'Автоматизация оформления и ведения клиентов через онлайн-кабинет Octopass экономит ваши ресурсы.',
    Svg: p => (
      <svg xmlns="http://www.w3.org/2000/svg" {...p} viewBox="0 0 480 480">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill="currentColor" fillRule="nonzero">
            <path
              d="M464,480 L32,480 C14.326888,480 0,465.673112 0,448 L0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 L32,448 L464,448 C472.836556,448 480,455.163444 480,464 C480,472.836556 472.836556,480 464,480 Z"
              id="Path"
            ></path>
            <g
              id="Group-30"
              transform="translate(264.000000, 248.000000) scale(-1, 1) translate(-264.000000, -248.000000) translate(64.000000, 80.000000)"
            >
              <path
                d="M76,336 L36,336 C16.117749,336 0,319.882251 0,300 L0,148 C0,128.117749 16.117749,112 36,112 L76,112 C95.882251,112 112,128.117749 112,148 L112,300 C112,319.882251 95.882251,336 76,336 Z"
                id="Path"
              ></path>
              <path
                d="M220,336 L180,336 C160.117749,336 144,319.882251 144,300 L144,100 C144,80.117749 160.117749,64 180,64 L220,64 C239.882251,64 256,80.117749 256,100 L256,300 C256,319.882251 239.882251,336 220,336 L220,336 Z"
                id="Path"
              ></path>
              <path
                d="M363.64,336 L323.64,336 C303.757749,336 287.64,319.882251 287.64,300 L287.64,36 C287.64,16.117749 303.757749,0 323.64,0 L363.64,0 C383.522251,0 399.64,16.117749 399.64,36 L399.64,300 C399.64,319.882251 383.522251,336 363.64,336 Z"
                id="Path"
              ></path>
            </g>
          </g>
        </g>
      </svg>
    ),
  },
]
